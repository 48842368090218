:root {
    --green: #07EA77;
    --darkgreen: #29645D;
    --darkestgreen: #073028;
    --lightgrey: #F2F1F6;
    --hovergrey: #E6E4ED;
    --textgrey: #A9A8AA;
    --midgrey: #AAAAAB;
    --slategrey: #4C5758;
  }
  
  .clicked_info_inner {
    padding: 10px;
    font-size: 16px;
    color: var(--slategrey);
    line-height: 1.5;
}

.clicked_info_inner h2 {
    font-weight: normal;
    color: var(--darkestgreen);
    margin-top: 0px;
    font-size: 16px;
}

.clickedInfoLeftHandColumn {
    display: inline-block;
    padding: 0px;
    margin-left: -2px;
}

.clickedInfoRightHandColumn {
    /* display: inline-block; */
    float: right;
    margin-right: -7px;
    padding: 0px;
    font-weight: medium;
}

.clickedInfoTable {
    width: 100%;
}

.clicked_info_parcelRefTitle {
    position: relative;
    width: 100%;
    display: block;
}

.clicked_info_parcelRef {
    position: relative;
    width: 100%;
    display: block;
}

.clicked_info_inner h2 {
    /* background-color: #00ff00; */
    font-size: 22px;
}

.clicked_info_innerTitle {
    color: #000;
    position: relative;
    width: 100%;
    display: block;
}

.clicked_info_innerHabitat {
    color: #000;
    display: block;
    position: relative;
    width: 100%;
    min-width: 150px;
}

.clicked_info_header {
    width: 100%;
    height: 20px;
}

.clickedInfo1 {
    color: #000;
    padding: 0px;
    margin-top: 50px;
    width: 100%;
    /* background-color: #0000ff; */
    height: 70px;
    display: block;
    position: relative;
}

.clickedInfo2 {
    color: #000;
    margin-top: 15px;
    padding: 0px;
    width: 100%;
    /* background-color: #00ff00; */
    height: 50px;
    position: relative;
    display: block;
}

.clickedInfo3 {
    color: #000;
    padding: 0px;
    /* font-size: 14px; */
    margin-top: 30px;
    width: 100%;
    height: 70px;
    /* background-color: #ff0000; */
    position: relative;
    display: block;
}

.divider{
    margin-top: 15px;
    left: 0px;
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: var(--midgrey);
}

.clickedInfo3 p {
    font-size: 16px;
}

.clicked_info_metric {
    font-size: 14px;
}

.clicked_info_value {
    font-size: 14px;
}

.clicked_info_more_button {
    width: 100%;
    position: relative;
    width: 200px;
    left: 50%;
    top: 25px;
    transform: translate(-50%);
    text-align: center;
    border-radius: 3px;
    padding: 12.5px;
    font-size: 16px;
    margin-bottom: 35px;
    background-color: var(--hovergrey);
  }
  
  .clicked_info_more_button:hover {
    cursor: pointer;
    background-color: var(--hovergrey);
  }
  
  .clicked_info_close:hover {
    cursor: pointer;
  }