@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: "Figtree", sans-serif;
  user-select: none;
  -moz-user-select: none;
  -webkit-text-select: none;
  -webkit-user-select: none;
  outline: none;
}

input {
  user-select: none;
  -moz-user-select: none;
  -webkit-text-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

input:focus,
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

select{
  appearance: none;
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
}

:root {
  --green: #07EA77;
  --darkgreen: #29645D;
  --darkestgreen: #073028;
  --lightgrey: #F2F1F6;
  --hovergrey: #E6E4ED;
  --textgrey: #A9A8AA;
  --midgrey: #AAAAAB;
  --slategrey: #4C5758;
}

body {
  overflow: hidden;
}

h2 {
  font-weight: 800;
}

button {
  border: none;
  background-color: transparent;
  font-size: 16px;
}

button:hover {
  cursor: pointer;
}

.select-wrapper {
  position: absolute;
  display: inline-block;
  left: 40px;
  top: 25px;
}

.select-wrapper select {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  outline: none;
  width: 170px;
}

.select-wrapper select option {
  padding: 8px 16px;
}

select:focus {
  outline: none;
}

.select-container {
  display: inline-block;
}

.dropdownselect {
  margin-top: 20px;
  margin-left: 15px;
  padding: 10px 20px;
  font-size: 16px;
  border: 0px;
  border-radius: 5px;
  appearance: none;
  background-color: #fff;
  cursor: pointer;
  width: 225px;
}

.metricSelectionModify {
  padding: 8px 20px;
  font-size: 16px;
  border: 0px;
  border-radius: 0px;
  appearance: none;
  background-color: #fff;
  cursor: pointer;
  width: 280px;
  margin: 0px 10px;
  margin-top: 20px;
  border-radius: 3px;
  color: var(--darkestgreen);
}

.yearSelectionModify {
  padding: 8px 20px;
  font-size: 16px;
  border: 0px;
  border-radius: 0px;
  appearance: none;
  background-color: #fff;
  cursor: pointer;
  width: 280px;
  margin: 0px 10px;
  margin-top: 20px;
  border-radius: 3px;
  color: var(--darkestgreen);
}

.yearSelectionModifyPublic{
  padding: 8px 20px;
  font-size: 16px;
  border: 0px;
  border-radius: 0px;
  appearance: none;
  cursor: pointer;
  width: 280px;
  margin: 0px 10px;
  margin-top: 20px;
  border-radius: 3px;
  color: var(--midgrey);
  background-color: #aaaaab;
  background-color: var(--lightgrey);
}

.yearSelectionModify:focus {
  outline: none;
}

.select-arrow {
  position: relative;
  float: right;
  margin-right: 20px;
  pointer-events: none;
  margin-top: -16px;
}

.select-arrow::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555;
}

.select-arrow-year {
  position: relative;
  float: right;
  margin-right: 20px;
  pointer-events: none;
  margin-top: -16px;
}

.select-arrow-year::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.clicked_benchmark_info {
  font-size: 12px;
  z-index: 15;
  width: 215px;
  height: 300px;
  background-color: var(--lightgrey);
  padding: 12px;
  border-radius: 3px;
}

.clicked_benchmark_info h2 {
  font-size: 16px;
}

.clicked_info {
  font-size: 12px;
  right: 15px;
  top: 150px;
  position: absolute;
  z-index: 15;
  width: 325px;
  height: auto;
  overflow: scroll;
  background-color: var(--lightgrey);
  padding: 12px;
  border-radius: 3px;
}

.clicked_info.mobile {
  font-size: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 20;
  width: 275px;
  height: auto;
  overflow: scroll;
  background-color: #fff;
  padding: 12px;
  border-radius: 3px;
}

.clicked_info_close {
  position: relative;
  right: 0px;
  top: 0px;
  float: right;
  background-color: var(--lightgrey);
  text-align: center;
  border-radius: 3px;
  padding-top: 3px;
  padding-bottom: 6px;
  font-size: 12px;
}

.clicked_info_close_icon_mobile {
  position: relative;
  right: 0px;
  top: 0px;
  float: right;
  background-color: #fff;
  text-align: center;
  border-radius: 3px;
  width: 60px;
  padding-top: 3px;
  padding-bottom: 6px;
  font-size: 12px;
}

.clickedInfoTable {
  width: 100% !important;
}

.clickedInfoTable td {
  padding: 0px;
}

.clickedInfoValue {
  text-align: right !important;
}

.clicked_info__close {
  position: relative;
  margin-bottom: 15px;
  top: 20px;
  right: 0px;
  width: 220px;
  padding: 15px;
  border-radius: 3px;
  background-color: var(--green);
  z-index: 10;
  color: #000;
  text-align: center;
  font-size: 16px;
  float: right;
}

.clicked_info__close:hover {
  background-color: var(--darkgreen);
  color: #fff;
  cursor: pointer;
}

.clicked_info__close_cancel {
  position: relative;
  margin-bottom: 15px;
  top: 20px;
  left: 0px;
  width: 220px;
  padding: 15px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  z-index: 10;
  color: #000;
  text-align: center;
  font-size: 16px;
  float: left;
}

.clicked_info__close_cancel:hover {
  color: #000;
  cursor: pointer;
}

.dropdown_list_outer {
  right: 15px;
  top: 135px;
  z-index: 10;
  width: 250px;
  height: 500px;
  border-radius: 3px;
}

.dropdown_list {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 400px;
  padding: 0px;
  border-radius: 3px;
  overflow: scroll;
  overflow-x: hidden;
}

.dropdown_list::-webkit-scrollbar {
  width: 10px;
}

.dropdown_list::-webkit-scrollbar-thumb {
  background-color: var(--darkgreen);
  border-radius: 5px;
  border: 2px solid var(--lightgrey);
}

.dropdown_list::-webkit-scrollbar-track {
  background: var(--lightgrey);
  border-radius: 5px;
}

.itemblock {
  width: 90%;
  padding-top: 0px;
  padding-bottom: 5px;
  padding-left: 0px;
  padding-right: 5px;
  border-radius: 3px;
}

.highlighted {
  background-color: var(--hovergrey);
}

.list_item {
  font-size: 12px;
  display: inline-block;
  position: absolute;
  margin-top: 10px;
}

.itemblock:hover {
  background-color: var(--hovergrey);
  cursor: pointer;
}

.color_circle {
  border-radius: 29px;
  height: 14px;
  width: 14px;
  display: inline-block;
  margin-right: 20px;
  margin-left: 10px;
  margin-top: 13px !important;
  position: absolute;
}

.color_circle_landusechange {
  border-radius: 29px;
  height: 14px;
  width: 14px;
  display: inline-block;
  margin-right: 10px;
  margin-left: 5px;
  margin-top: 10px !important;
}

.ukhabcolorcircle {
  border-radius: 29px;
  height: 14px;
  width: 14px;
  display: inline-block;
  margin-right: 20px;
  margin-top: 10px !important;
  margin-left: 10px;
}

.ukhabcolorcircledropdown {
  border-radius: 29px;
  height: 14px;
  width: 14px;
  display: inline-block;
}

.loading {
  position: absolute;
  opacity: 1;
  top: 0px;
  right: 0px;
  padding: 12px;
  background-color: #fff;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.loading-bar-container {
  width: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10000;
  height: 50px;
  background-color: transparent;
  overflow: hidden;
}

.loading-bar {
  height: 100%;
  background-color: #007bff;
  transition: width 0.5s ease;
  /* Adjust the duration and easing as needed */
  text-align: center;
  color: white;
}

.loading_text {
  position: absolute;
  top: 50%;
  height: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}

.leftMenuIcons {
  padding-right: 10px;
  margin-bottom: -2px;
}

.land_types {
  position: relative;
  top: 40px;
  width: 260px;
  padding: 10px;
  border-radius: 3px;
  z-index: 10;
  margin: 0px 10px;
  color: var(--darkestgreen);
}

.land_types.selected {
  background-color: var(--hovergrey);
  color: var(--darkestgreen);
}

.land_types:hover {
  background-color: var(--hovergrey);
  color: #000;
  cursor: pointer;
}

.land_types_accum {
  position: relative;
  width: 275px;
  left: 30%;
  top: 25px;
  transform: translate(-27.5%, 0%);
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  font-size: 12px;
  height: 300px;
  overflow: scroll;
  overflow-x: hidden;
}

.land_types_accum::-webkit-scrollbar {
  width: 10px;
}

.land_types_accum::-webkit-scrollbar-thumb {
  background-color: var(--darkgreen);
  border-radius: 5px;
  border: 2px solid var(--lightgrey);
}

.land_types_accum::-webkit-scrollbar-track {
  background: var(--lightgrey);
  border-radius: 5px;
}

.land_types_accum p {
  display: inline-block;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 40px;
}

.land_types_accum_landkey {
  position: relative;
  width: 275px;
  left: 30%;
  top: 25px;
  height: 500px;
  transform: translate(-27.5%, 0%);
  border-radius: 3px;
  overflow-y: scroll;
  background-color: #fff;
  z-index: 10;
  font-size: 12px;
  overflow-x: hidden;
}

.land_types_accum_landkey::-webkit-scrollbar {
  width: 10px;
}

.land_types_accum_landkey::-webkit-scrollbar-thumb {
  background-color: var(--darkgreen);
  border-radius: 5px;
  border: 2px solid var(--lightgrey);
}

.land_types_accum_landkey::-webkit-scrollbar-track {
  background: var(--lightgrey);
  border-radius: 5px;
}

.land_types_accum_landkey p {
  display: inline-block;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 40px;
  line-height: 1.5;
}

.ResetLandtypeListButton {
  width: 100px;
  right: 0px;
  text-align: center;
  display: inline-block;
  padding: 10px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  z-index: 10;
  font-size: 12px;
}

.ResetLandtypeListButton:hover {
  cursor: pointer;
  background-color: var(--darkgreen);
  color: #fff;
}

.HideAllLandtypeListButton {
  top: 0px;
  width: 100px;
  float: right;
  text-align: center;
  padding: 10px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  z-index: 10;
  font-size: 12px;
}

.HideAllLandtypeListButton:hover {
  cursor: pointer;
  background-color: var(--darkgreen);
  color: #fff;
}

.land_type_item {
  left: 0px;
  width: 250px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.selectedLandTypeTitle {
  margin-left: 50px;
  margin-top: 26px
}

.land_type_item_text {
  display: inline-block;
  margin-top: 7.5px !important;
  margin-bottom: 7.5px !important;
}

.land_type_item_text_mobile {
  display: inline-block;
  margin-top: 0px !important;
  margin-bottom: 7.5px !important;
  margin-left: 40px;
}

.land_type_item_textLargePie {
  display: inline-block;
  margin-left: 50px;
  margin-top: 14px !important;
  margin-bottom: 7.5px !important;
}

.land_type_item_key {
  display: inline-block;
}

.land_type_item_value {
  float: right;
  right: 30px;
  margin-top: -10px;
  position: absolute;
}

.land_type_item.selected {
  background-color: var(--lightgrey);
}

.land_type_item:hover {
  background-color: var(--lightgrey);
  cursor: pointer;
}

.modifyPanel {
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  margin-top: 70px;
  text-align: left;
  margin-bottom: 40px;
}

.modifyPanelInner {}

.modifyPanelInner h2 {
  margin-top: 10px;
  margin-left: 5px;
}

.end_draw {
  text-align: center;
  background-color: var(--lightgrey);
  border-radius: 3px;
  padding: 8px;
  width: 236.5px;
}

.end_draw.active {
  font-size: 14px !important;
  background-color: var(--green);
}

.end_draw:hover {
  cursor: pointer;
  background-color: var(--darkgreen);
}

.end_draw:hover>.end_draw_button {
  cursor: pointer;
  background-color: var(--darkgreen);
  color: #fff !important;
}

.end_draw_cancel {
  position: absolute;
  float: right;
  right: 12.5px;
  top: 0px;
  background-color: var(--lightgrey);
  text-align: center;
  border-radius: 3px;
  padding: 10px;
  width: 60px;
  font-size: 14px !important;
}

.end_draw_cancel:hover {
  cursor: pointer;
  background-color: var(--hovergrey);
}

.end_draw_cancel:hover>.end_draw_button_cancel {
  cursor: pointer;
  background-color: var(--hovergrey);
}

.end_draw_button_cancel {
  font-size: 10px !important;
}

.end_draw_button:hover {
  cursor: pointer;
  color: #fff;
}

.mapboxgl-control-container {
  position: absolute;
  z-index: 12;
  top: 240px;
  left: 325px;
}

.mapbox-gl-draw_polygon {
  background-color: var(--lightgrey);
  border-radius: 3px;
  padding: 8px;
  text-align: center;
  width: 115px;
  color: var(--darkestgreen);
}

.mapbox-gl-draw_polygon:hover {
  cursor: pointer;
  background-color: var(--hovergrey);
}

.mapbox-gl-draw_polygon.active {
  background-color: var(--green);
}

.mapbox-gl-draw_polygon::after {
  content: 'Draw';
}

.mapbox-gl-draw_trash {
  text-align: center;
  background-color: var(--lightgrey);
  border-radius: 3px;
  padding: 8px;
  margin-left: 12.5px;
  width: 125px;
  color: var(--darkestgreen);
}

.mapbox-gl-draw_trash:hover {
  cursor: pointer;
  background-color: var(--hovergrey);
}

.mapbox-gl-draw_trash::after {
  content: 'Delete';
}

.mapboxgl-ctrl-attrib {
  display: none;
}

.save_as_blocked,
.save_blocked,
.load_blocked {
  opacity: .5;
  pointer-events: none;
}

.save_as,
.save_as_blocked {
  position: absolute;
  top: 2.5px;
  left: 15px;
  padding: 12px;
  color: #fff;
  z-index: 10;
}

.save_as:hover {
  cursor: pointer;
  color: var(--green);
}

.saved_filename_text {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 12px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
}

.load,
.load_blocked {
  position: absolute;
  top: 2.5px;
  left: 172px;
  padding: 12px;
  color: #fff;
  z-index: 10;
}

.load:hover {
  cursor: pointer;
  color: var(--green)
}

.loadfiletitle {
  display: block;
}

.closeBtn {
  display: inline-block;
  background-color: #fff;
  border-radius: 3px;
  color: #000;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: var(--darkgreen);
  color: #fff;
  width: 139px;
}

.filename-input-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 16px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  height: 50px;
  width: 500px;
  transform: translate(-50%, -50%);
}

.filename-input-modal__buttons {
  position: absolute;
  left: 0;
  top: 90px;
  width: 100%;
  border-radius: 0px 0px 3px 3px;
  height: 90px;
  background-color: var(--lightgrey);
  z-index: 10;
  text-align: center;
  -webkit-border-radius: 0px 0px 3px 3px;
  -moz-border-radius: 0px 0px 3px 3px;
  -ms-border-radius: 0px 0px 3px 3px;
  -o-border-radius: 0px 0px 3px 3px;
  border-top: 1px solid var(--hovergrey);
}

.saveWithFileName {
  display: inline-block;
  background-color: #fff;
  border-radius: 3px;
  margin-left: 25px;
  color: #000;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--darkgreen);
  color: #fff;
  width: 139px;
}

.saveWithFileName:hover {
  cursor: pointer;
}

.closeSaveWithFilename {
  display: inline-block;
  background-color: #fff;
  border-radius: 3px;
  margin-right: 25px;
  margin-top: 25px;
  color: #000;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  color: #000;
  width: 139px;
}

.closeSaveWithFilename:hover {
  cursor: pointer;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  width: 500px;
  height: 250px;
  -webkit-box-shadow: 0px -1px 16px -3px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px -1px 16px -3px rgba(0, 0, 0, 0.45);
  box-shadow: 0px -1px 16px -3px rgba(0, 0, 0, 0.45);
}

.modal-content p {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: var(--darkestgreen);
}

.modal-contentImportant {
  margin-top: 125px;
}

.filename-input-modal__input {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  z-index: 10;
  width: 375px;
  border: 0px;
}

.loadedFileName {
  position: absolute;
  top: 2.5px;
  left: 240px;
  padding: 13px;
  border-radius: 3px;
  color: #fff;
  z-index: 10;
}

.save,
.save_blocked {
  position: absolute;
  top: 2.5px;
  left: 105px;
  padding: 12px;
  color: #fff;
  z-index: 10;
}

.save:hover {
  cursor: pointer;
  color: var(--green);
}

.top_menu {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  background-color: #fff;
  z-index: 20;
}

.top_menu_Mobile {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 12;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  margin-top: 7px;
  max-width: 60px;
}

.menuItems {
  position: absolute;
  left: 100px;
}

.menuItem {
  display: inline-block;
  padding: 20px;
  font-size: 16px;
  color: var(--slategrey);
}

.menuItemMobile {
  display: block;
  padding: 10px;
  margin-top: 5px;
  margin-left: 10px;
  margin-bottom: 5px;
  width: calc(100% - 40px);
  border-radius: 5px;
  font-size: 22px;
  color: var(--darkgreen);
}

.menuItemMobile:hover {
  cursor: pointer;
  color: var(--darkgreen);
  background-color: var(--hovergrey);
}

.menuItem:hover {
  cursor: pointer;
  color: var(--darkgreen);
}

.top_Submenu {
  position: absolute;
  top: 60px;
  left: 0px;
  width: 100%;
  height: 50px;
  background-color: var(--darkgreen);
  z-index: 20;
}

.top_Submenu.inabout {
  position: absolute;
  top: 60px;
  left: 0px;
  width: 100%;
  height: 50px;
  background-color: var(--slategrey);
  z-index: 20;
}

.topSubmenuBackButton {
  position: absolute;
  top: 50%;
  width: 150px;
  margin-left: 25px;
  transform: translate(0%, -50%);
  left: 0px;
  padding: 10px;
  border-radius: 3px;
  font-size: 16px;
  background-color: #fff;
}

.top_Submenu_metricDisplay{
  position: absolute;
  margin-left: 715px;
  /* top: 50%; */
  width: auto;
  /* margin-left: 25px; */
  /* transform: translate(0%, -50%); */
  right: 5px;
  color: #fff;
  width: auto;
  padding: 0px;
  border-radius: 3px;
  font-size: 16px;
  /* background-color: #ff0000; */
}

.top_Submenu_metricDisplay p{
  display: inline-block;
}

.top_Submenu_metricDisplayMetric{
  display: inline-block;
  margin-left: 10px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.top_Submenu_metricDisplayMetricTitle{
  color: var(--green);
}

.top_Submenu_metricTotalsDisplay{
  position: absolute;
  /* top: 50%; */
  width: auto;
  /* margin-left: 25px; */
  /* transform: translate(0%, -50%); */
  right: 325px;
  color: #fff;
  width: auto;
  padding: 0px;
  border-radius: 3px;
  font-size: 16px;
  /* background-color: #ff0000; */
}

.top_Submenu_metricTotalsDisplay p{
  display: inline-block;
}

.top_Submenu_metricTotalsDisplayMetric{
  display: inline-block;
  margin-left: 10px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.top_Submenu_metricTotalsDisplayMetricTitle{
  color: var(--green);
}

.top_Submenu__right {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 3.5px 0px;
  color: #fff;
}

.top_Submenu__right p {
  margin: 12px;
  margin-right: 30px;
  display: inline-block;
}

.top_Submenu__right__menu__load__button {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  background-color: var(--darkestgreen);
  padding: 10px;
  width: 185px;
  margin-right: 20px;
  border-radius: 3px;
}

.title {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
}

.auth {
  position: absolute;
  top: 50%;
  width: auto;
  right: 3px;
  /* background-color: #ff0000; */
  transform: translate(0%, -50%);
  font-size: 30px;
  /* background-color: #00ff00; */
}

.authAccountButton{
  /* display: inline-block; */
  margin-left: 20px;
  float: right;
  width: 50px;
  margin-top: 10px;
  /* background-color: #00ff00; */
}

.auth p {
  /* margin: 0px; */
  /* top: -10px; */
  float: left;
  /* position: absolute; */
  /* display: inline-block; */
  padding: 0px;
  font-size: 16px;
  color: var(--slategrey);
  padding: 0px;
}

.auth:hover {
  cursor: pointer;
}

.loginForm {
  position: absolute;
  top: 140px;
  right: 20px;
  padding: 16px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 30;
  width: 300px;
  height: 150px;
  text-align: center;

  -webkit-box-shadow: 0px -1px 16px -3px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px -1px 16px -3px rgba(0, 0, 0, 0.45);
  box-shadow: 0px -1px 16px -3px rgba(0, 0, 0, 0.45);
}

.loginForm.inabout {
  position: absolute;
  top: 140px;
  right: 20px;
  padding: 16px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  z-index: 30;
  width: 300px;
  height: 325px;
  text-align: center;
}

.loginForm.inabout input {
  background-color: #fff;
}

.loginForm p {
  margin-bottom: 20px;
  font-size: 14px;
  padding-top: 10px;
}

.loginBtn {
  padding: 12px;
  background-color: var(--darkgreen);
  border-radius: 3px;
  color: #fff;
  width: 150px;
  z-index: 10;
  position: absolute;
  display: block;
  left: 50%;
  bottom: 40px;
  transform: translate(-50%);
}

.loginBtn:hover {
  background-color: var(--darkestgreen);
  cursor: pointer;
}

.forgotPasswordBtn {
  color: var(--textgrey);
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 14px;
}

.forgotPasswordBtn:hover {
  cursor: pointer;
  color: #000;
}

.closeAuth {
  color: var(--textgrey);
  z-index: 10;
  position: absolute;
  display: block;
  right: 15px;
  margin-top: 5px;
  font-size: 24px;
}

.closeAuth:hover {
  cursor: pointer;
  color: #000;
}

.emailInput {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 16px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  z-index: 10;
  width: 250px;
  border: 0px;
}

.passwordInput {
  position: absolute;
  top: 175px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 16px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  z-index: 10;
  width: 250px;
  border: 0px;
}

.registerBtn {
  padding: 12px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  display: block;
}

.updateediteddisplayNameBtn {
  padding: 12px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  display: block;
}

.authEmail {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.signOutBtn {
  padding: 10px;
  background-color: var(--darkgreen);
  border-radius: 3px;
  color: #fff;
  width: 150px;
  z-index: 10;
  margin-top: 10px;
  /* position: absolute; */
  display: block;
  /* left: 50%; */
  /* bottom: 40px; */
  /* transform: translate(-50%, 0%); */
}

.authEmail{
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  margin-top: -25px;
  width: 250px;
  /* background-color: #ff0000; */
}

.loggedInButtonBox{
  margin-top: 35px;
  margin-left: 70px;
}

.settingsBtn {
  padding: 10px;
  background-color: var(--darkgreen);
  border-radius: 3px;
  color: #fff;
  width: 150px;
  z-index: 10;
  /* position: absolute; */
  display: block;
  /* left: 50%; */
  /* bottom: 90px; */
  /* transform: translate(-50%, 0%); */
}

.sendLinkBtn {
  padding: 12px;
  background-color: var(--darkgreen);
  border-radius: 3px;
  color: #fff;
  width: 150px;
  z-index: 10;
  /* position: absolute; */
  display: block;
  left: 50%;
  bottom: 90px;
  transform: translate(-50%, 0%);
}

.emailInputForgotPassword {
  position: absolute;
  top: 160px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 16px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  z-index: 10;
  width: 250px;
  border: 0px;
}

.backToLoginBtn {
  padding: 12px;
  background-color: var(--hovergrey);
  border-radius: 3px;
  color: #000;
  width: 150px;
  z-index: 10;
  position: absolute;
  display: block;
  left: 50%;
  bottom: 30px;
  transform: translate(-50%, 0%);
}

.sentMessage {
  margin-top: 100px;
}

.togglerDrawInside {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(0%, -50%);
  width: 30px;
  height: 65px;
  background-color: #fff;
  z-index: 10;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.togglerDrawInside.mobile {
  display: none;
}

.togglerDrawInside:hover {
  cursor: pointer;
  background-color: var(--darkgreen);
  color: #fff;
}

.togglerDrawOutside {
  position: absolute;
  top: calc(50% - 100px);
  right: -30px;
  transform: translate(0%, -50%);
  width: 30px;
  height: 65px;
  background-color: #fff;
  z-index: 20;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.togglerDrawOutside:hover {
  cursor: pointer;
  background-color: var(--darkgreen);
  color: #fff;
}

.leftMenuButton {
  position: absolute;
  top: 100px !important;
  left: 0px;
  height: calc(100% - 100px);
  width: 75px;
  z-index: 11;
}

.leftMenu {
  padding-top: 80px;
  position: absolute;
  top: 110px !important;
  left: 250px;
  height: calc(100% - 100px);
  width: 300px !important;
  z-index: 10;
  background-color: var(--lightgrey) !important;
  box-shadow: none !important;
}

.leftMenu h2 {
  margin-left: 30px;
  margin-bottom: 0px;
  color: var(--darkestgreen);
  font-weight: normal;
}

.leftSubMenu_cancel {
  position: absolute;
  float: right;
  right: 25px;
  top: 25px;
  background-color: var(--lightgrey);
  text-align: center;
  border-radius: 3px;
  z-index: 20;
  padding: 2px;
  width: 60px;
  font-size: 12px !important;
}

.leftSubMenu_cancel:hover {
  cursor: pointer;
  background-color: var(--hovergrey);
}

.leftSubMenu_cancel button {
  font-size: 10px !important;
}

.leftSubMenu {
  position: absolute;
  top: 110px !important;
  left: 300px !important;
  height: calc(100% - 100px);
  width: 300px !important;
  background-color: #fff;
  z-index: 9 !important;
  box-shadow: none !important;
}

.leftSubMenu h2 {
  margin-left: 30px;
  padding-top: 40px;
}

.genericSubMenuHelpBox {
  width: 100%;
  height: 300px;
  font-size: 12px;
  left: 50%;
  top: 50px;
  position: absolute;
  transform: translate(-50%, 0%);
}

.genericSubMenuHelpBoxinner {
  width: 250px;
  left: 25px;
  top: 30px;
  height: auto;
  font-size: 12px;
  position: absolute;
}

.genericSubMenuHelpBoxinner h1 {
  color: var(--darkestgreen);
  font-weight: normal;
  font-size: 24px;
}

.genericSubMenuHelpBoxinner h3 {
  color: var(--slategrey);
  font-weight: normal;
}

.genericSubMenuHelpBoxinner p {
  color: var(--slategrey);
  line-height: 1.5;
}


.scrollingBenchmarking {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 600px;
}

.scrollingBenchmarking::-webkit-scrollbar {
  width: 10px;
}

.scrollingBenchmarking::-webkit-scrollbar-thumb {
  background-color: var(--darkgreen);
  border-radius: 5px;
  border: 2px solid var(--lightgrey);
}

.scrollingBenchmarking::-webkit-scrollbar-track {
  background: var(--lightgrey);
  border-radius: 5px;
}

.land_type_list_general_p_compare {
  border-radius: 3px;
  padding-bottom: 17px;
  width: 250px;
}

.land_type_list_general_p_compare:hover {
  cursor: pointer;
  padding-bottom: 14px;
  background-color: var(--hovergrey);
}

.land_type_list_general {
  border-radius: 3px;
  font-size: 16px;
}

.land_type_list_general_p {
  padding-top: 0px;
  border-radius: 3px;
  width: 250px;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-bottom: 2px;
}

.land_type_list_general_p:hover {
  cursor: pointer;
  padding-top: 0px;
  width: 250px;
  padding-bottom: 3px;
  padding-top: 3px;
  background-color: var(--lightgrey);
}

.land_type_list_general .selectedLandType {
  background-color: var(--lightgrey);
}

.land_type_list {
  padding-top: 8px;
  padding-bottom: 8px;
  overflow-y: scroll;
}

.land_type_list p {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}

.land_type_list p.selectedLandType {
  background-color: var(--hovergrey);
}

.pieChart {
  width: 70% !important;
  height: auto !important;
  margin-left: 15% !important;
  margin-top: 20% !important;
}

.enlargePieButton {
  position: relative;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0%);
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  z-index: 10;
  width: 90px;
}

.enlargePieButton button {
  font-size: 10px !important;
}

.enlargePieButton:hover {
  cursor: pointer;
  background-color: var(--darkgreen);
  color: #fff;
}

.enlargePieButton:hover>button {
  color: #fff;
}

.LargepieChartWindow {
  width: 1100px;
  position: absolute;
  height: 800px;
  border-radius: 6px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}

.largepieChart {
  width: 450px !important;
  height: 450px !important;
  margin-left: 125px;
  margin-top: 150px;
}

.largeland_types_accum {
  position: absolute;
  top: 85px;
  height: 600px;
  right: 0px;
  width: 400px;
  overflow-y: scroll;
  display: inline-block;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  font-size: 12px;
}

.largeland_types_accum::-webkit-scrollbar {
  width: 10px;
}

.largeland_types_accum::-webkit-scrollbar-thumb {
  background-color: var(--darkgreen);
  border-radius: 5px;
  border: 2px solid var(--lightgrey);
}

.largeland_types_accum::-webkit-scrollbar-track {
  background: var(--lightgrey);
  border-radius: 5px;
}

.largeland_types_accum p {
  display: inline;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.largePieChartTitle {
  position: absolute;
  top: 20px;
  left: 40px;
  padding: 10px;
  color: #000;
  background-color: #fff;
  z-index: 10;
}

.largePieChartLandTypesTitle {
  position: absolute;
  top: 20px;
  right: 200px;
  width: 200px;
  color: #000;
  background-color: #fff;
  z-index: 10;
}

.land_type_item_value_large {
  float: right;
  right: 60px;
  position: absolute;
}

.selectedLandTypeInfo {
  position: absolute;
  left: 40px;
  width: 500px;
  color: #000;
  background-color: #fff;
  z-index: 10;
}

.selectedLandTypeInfo h4 {
  font-weight: 400;
  font-size: 10px;
  color: var(--slategrey);
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.closelargePieButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 15;
  width: 20px;
  height: 20px;
  text-align: center;
}

.closelargePieButton:hover {
  cursor: pointer;
}

.resetLargePieButton {
  position: absolute;
  width: 100px;
  right: 280px;
  z-index: 20;
  bottom: 20px;
  width: 100px;
  text-align: center;
  display: inline-block;
  padding: 10px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  z-index: 10;
  font-size: 12px;
}

.resetLargePieButton:hover {
  cursor: pointer;
  background-color: var(--darkgreen);
  color: #fff;
}

.resetLargePieButton:hover>button {
  color: #fff;
}

.resetLargePieButton button {
  font-size: 10px !important;
}

.resetPieButton {
  width: 100px;
  z-index: 20;
  margin-top: 20px;
  width: 100px;
  text-align: center;
  display: inline-block;
  padding: 10px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  z-index: 10;
  font-size: 10px;
}

.resetPieButton button {
  font-size: 10px !important;
}

.resetPieButton:hover {
  cursor: pointer;
  background-color: var(--darkgreen);
  color: #fff;
}

.resetPieButton:hover>button {
  color: #fff;
}

.land_types_accum_compare {
  position: relative;
  top: 25px;
  width: 275px;
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
  left: 30%;
  transform: translate(-27.5%, 0%);
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  font-size: 12px;
}

.land_types_accum_compare::-webkit-scrollbar {
  width: 10px;
}


.land_types_accum_compare::-webkit-scrollbar-thumb {
  background-color: var(--darkgreen);
  border-radius: 5px;
  border: 2px solid var(--lightgrey);
}

.land_types_accum_compare::-webkit-scrollbar-track {
  background: var(--lightgrey);
  border-radius: 5px;
}

.land_types_accum_compare p {
  display: inline-block;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 40px;
}

.land_type_list_general_p_compare {
  border-radius: 3px;
  padding-bottom: 0px;
}

.land_type_list_general_p_compare:hover {
  cursor: pointer;
  border-radius: 3px;
  padding-bottom: 0px;
  background-color: var(--lightgrey);
}

span {
  display: block;
  text-align: center;
}

.modify_button.selected {
  background-color: var(--lightgrey);
  color: #000;
  border-color: var(--lightgrey);
}

.modify_button {
  position: absolute;
  top: 240px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  width: 195px;
  text-align: left;
}

.modify_button:hover {
  cursor: pointer;
  background-color: var(--lightgrey);
}

.landuse_button {
  position: absolute;
  top: 280px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  width: 195px;
  text-align: left;
}

.landuse_button:hover {
  cursor: pointer;
  background-color: var(--lightgrey);
}

.landuse_button.selected {
  background-color: var(--lightgrey);
  color: #000;
  border-color: var(--lightgrey);
}

.compare_button {
  position: absolute;
  top: 325px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  width: 195px;
  text-align: left;
}

.compare_button.selected {
  background-color: var(--lightgrey);
  color: #000;
  border-color: var(--lightgrey);
}

.compare_button:hover {
  cursor: pointer;
  background-color: var(--lightgrey);
}

.benchmark_button {
  position: absolute;
  top: 365px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  width: 195px;
  text-align: left;
}

.benchmark_button:hover {
  cursor: pointer;
  background-color: var(--lightgrey);
}

.benchmark_button.selected {
  background-color: var(--lightgrey);
  color: #000;
  border-color: var(--lightgrey);
}

.modifyingYearTitle {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px;
  color: #fff;
  border-radius: 3px;
  background-color: #000;
  z-index: 10;
}

.clickToModify {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
}

.clickToCompare {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
}

.compare {
  font-family: Arial, sans-serif;
  z-index: 5;
  position: absolute;
  top: 110px;
  right: 0px;
  background-color: var(--lightgrey);
  width: calc(100% - 680px);
  height: calc(100% - 190px);
  padding: 40px;
  padding-top: 80px;
}

.compare h2 {
  color: var(--darkestgreen);
  font-weight: normal;
}

.compare__container_one h2,
.compare__container_two h2 {
  position: relative !important;
  top: auto !important;
  left: auto !important;
}

.compare__container_one {
  border-radius: 5px;
  padding: 30px;
  max-width: 1500px;
  background-color: #fff;
  height: 70%;
}

.compare__container_two {
  border-radius: 5px;
  padding: 30px;
  max-width: 1500px;
  margin-top: 50px;
  background-color: #fff;
  margin-left: 50px;
}

.compare__container__title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.compare__container__content__item {
  margin-bottom: 20px;
}

.compare__container__content__item__title {
  font-weight: bold;
}

.compare__container__content__item__content {
  width: 1500px;
  margin-top: 5px;
}

.searchBar {
  position: absolute;
  top: 7px;
  left: 250px;
  padding: 10px;
  border-radius: 3px;
  border: 0px;
  background-color: #fff;
  z-index: 10;
  width: 300px;
}

.searchBar.mobile {
  position: absolute;
  top: 5.5px;
  left: 10px;
  padding: 11.5px;
  border-radius: 3px;
  border: 0px;
  background-color: #fff;
  z-index: 10;
  width: 60%;
}

.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  width: 300px;
  margin-left: 250px;
  margin-top: 30px;
  padding: 10px;
}

.suggestions.mobile {
  margin-left: 10px;
  width: 60%;
}

.suggestions li {
  padding: 10px 20px;
  cursor: pointer;
  color: #333;
}

.suggestions li:hover {
  background-color: var(--hovergrey);
}

.suggestions li:not(:last-child) {
  border-bottom: 1px solid var(--lightgrey)
}

.search_button {
  position: absolute;
  top: 10px;
  left: 740px;
  padding: 5px;
  border-radius: 25px;
  background-color: transparent;
  z-index: 10;
}

.geolocate {
  position: absolute;
  top: 10px;
  left: 597.5px;
  height: 33px;
  width: auto;
  border-radius: 3px;
  z-index: 10;
  border-radius: 30px;
}

.geolocate p {
  margin-top: 5px;
  float: left;
  margin-right: 10px;
  color: #fff;
  display: inline-block;
}

.geolocate.mobile {
  position: absolute;
  top: 11px;
  left: 72.5%;
  height: 33px;
  width: 33px;
  border-radius: 3px;
  z-index: 10;
  border-radius: 30px;
}

.geolocate:hover {
  cursor: pointer;
}

.geoIcon {
  margin-top: 1px;
  margin-left: -2px;
}

.compass {
  position: absolute;
  top: 9px;
  left: 725px;
  height: 33px;
  display: inline-block;
  width: auto;
  border-radius: 3px;
  z-index: 10;
  border-radius: 30px;
}

.compass p{
  margin-top: 5px;
  float: left;
  margin-right: 10px;
  color: #fff;
  display: inline-block;
}

.compass.mobile {
  position: absolute;
  top: 11px;
  left: 85%;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  z-index: 10;
  border-radius: 30px;
}

.compassIcon {
  margin-top: 2px;
  margin-left: -2px;
}

.controls_nav {
  position: absolute;
  /* display: flex; */
  justify-content: space-between;
  z-index: 10;
  border-radius: 3px;
  bottom: 20px;
  background-color: #fff;
  right: 30px;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 160px;
  height: auto;
}

.button-container button {
  padding: 5px;
  border-radius: 3px;
  height: 40px;
  width: 65px;
  display: inline-block;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 3px;
  color: #fff;
  background-color: var(--darkgreen);
}

.zoomOutBtn {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.zoomInBtn {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  background-color: #0000ff;
}

.controls_sliders {
  margin-top: 30px;
}

.controls_sliders input {
  width: 144px;
}

.slider-container {
  display: block;
  /* background-color: #00ff00; */
}

.closeNav {
  color: var(--textgrey);
  z-index: 10;
  position: absolute;
  display: block;
  right: 10px;
  top: 10px;
  font-size: 24px;
}

.slider-container label {
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
}

.controls_nav.mobile {
  display: none !important;
}

.controlsOpenButton {
  position: absolute;
  /* display: flex; */
  justify-content: space-between;
  z-index: 10;
  bottom: 20px;
  right: 20px;
}

.controlsOpenButton button {
  color: #fff;
  padding-left: 10px;
  border-radius: 3px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 2px;
  background-color: var(--darkgreen);
  color: #fff;
  width: 170px;
  text-align: center;
  height: 50px;
}

.general_nav {
  position: absolute;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  border-radius: 3px;
  bottom: 20px;
  background-color: #fff;
  right: 210px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 470px;
  height: 45px;
}

.general_nav.mobile {
  display: none !important;
}

.gnav_button {
  z-index: 10;
  margin-top: -1px;
  flex: 1;
}

.gnav_button p {
  margin-left: -45px;
  margin-top: 12px;
}

.checkbox {
  pointer-events: none !important;
}

.toggle_switch {
  position: absolute;
  display: inline-block;
  width: 45px;
  height: 32px;
  margin-right: 0px;
  left: 100px;
  top: 4px;
  pointer-events: none !important;
}

.toggle_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ukhabtoggle_switch {
  position: absolute;
  display: inline-block;
  width: 45px;
  height: 32px;
  margin-right: 0px;
  pointer-events: none !important;
  left: 95px;
  top: 4px;
}

.ukhabtoggle_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.general_nav_ukhab {
  position: absolute;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  border-radius: 3px;
  bottom: 20px;
  background-color: #fff;
  right: 700px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 150px;
  height: 45px;
}

.ukhabtitle {
  margin-left: -60px !important;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--darkgreen);
  transition: .4s;
  border-radius: 34px;
  margin-top: 10px !important;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: var(--hovergrey);
}

input:focus+.slider {
  box-shadow: 0 0 1px var(--hovergrey);
}

input:checked+.slider:before {
  transform: translateX(20px);
  background-color: var(--darkgreen);
}

.gnav_mv_toggle {
  background-color: #fff;
  border-radius: 3px;
  color: #000;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.gnav_mv_toggle.active {
  background-color: var(--darkgreen);
  color: #fff;
}

.loadDataMap {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 650px;
  padding: 30px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.loadDataMap p {
  margin-bottom: 20px;
  font-size: 18px;
  margin-left: 15px !important;
}

.imageGalleryContainer {
  overflow: scroll;
  height: 500px;
}

.imageGalleryImageContainer {
  padding-bottom: 25px;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  overflow: scroll;
  border-radius: 3px;
}

.imageGalleryImage {
  width: 100%;
  height: 200px;
  border-radius: 3px;
}

.imageGalleryImageCreated {
  font-size: 12px;
  margin-top: 15px;
  color: var(--textgrey);
}

.imageGalleryImageTitle {
  font-size: 16px;
  margin-top: 15px;
}

.imageGalleryImageContainer:hover {
  cursor: pointer;
  background-color: var(--lightgrey);
}

.imageGalleryImageContainer.selected {
  background-color: var(--lightgrey);
}

.closeBtnCont {
  position: absolute;
  z-index: 100;
  background-color: var(--lightgrey);
  height: 90px;
  width: 100%;
  right: 0px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: 1px solid var(--hovergrey);
  bottom: 0px
}

.closeLoadBtn {
  position: absolute;
  top: 25px;
  right: 210px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  font-size: 16px;
  width: 150px;
}

.openLoadBtn {
  position: absolute;
  top: 25px;
  right: 30px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 3px;
  background-color: var(--darkgreen);
  z-index: 10;
  font-size: 16px;
  width: 150px;
  color: #fff
}

.openLoadBtn:hover,
.closeLoadBtn:hover {
  cursor: pointer;
}

.openLoadBtn:hover {
  cursor: pointer;
  background-color: var(--darkestgreen);
}

.bottomBarLoad {
  background-color: var(--lightgrey);
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.barchart {
  margin-top: 30px;
  padding: 40px;
  max-height: 400px;
}

.compare__container__content__item__content {
  width: 100%;
  height: 20vh;
  /* or set to a specific vh unit */
}

.otherClass {
  height: 0px !important;
}

.settings {
  font-family: Arial, sans-serif;
  z-index: 20;
  position: absolute;
  top: 110px;
  right: 0px;
  background-color: var(--lightgrey);
  width: 100%;
  height: calc(100% - 110px);
  border-left: 1px solid #CECECE;
  overflow: scroll;
}

.settingsMobile {
  font-family: Arial, sans-serif;
  z-index: 20;
  position: absolute;
  top: 120px;
  right: 0px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  width: 100%;
  height: 100%;
}

.settingMobile_inner_leftMenu {
  width: 300px;
  height: 100%;
  position: absolute;
  background-color: transparent;
}

.setting_menu_button_mobile {
  position: relative;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px;
  border-radius: 3px;
  background-color: transparent;
  z-index: 10;
  width: 195px;
  text-align: left;
}

.settings_inner {
  width: calc(100% - 300px);
  margin-left: 300px;
  border-left: 2px solid #CECECE;
  max-width: 1750px;
}

.setting_inner_leftMenu {
  width: 270px;
  border-right: 2px solid #CECECE;
  height: 100%;
  position: absolute;
  background-color: transparent;
  padding-left: 30px;
}

.setting_inner_leftMenu h2 {
  margin-top: 100px;
  margin-bottom: 50px;
  color: var(--darkestgreen);
  font-weight: normal;
}

.settings_inner h2 {
  margin-top: 100px;
  margin-left: 30px;
  margin-bottom: 0px;
  color: var(--darkestgreen);
  font-weight: normal;
}

.settings__container_one {
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 51px;
  width: 95%;
  max-height: 320px;
}

.settings__container_two {
  border-radius: 5px;
  margin-top: 15px;
  padding-left: 30px;
  width: 95%;
  padding-right: 30px;
}

.settings__container_three {
  border-radius: 5px;
  margin-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.settings__container_four {
  border-radius: 5px;
  margin-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.settings__container__title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.settings__container__content__item {
  margin-bottom: 20px;
}

.settings__container__content__item__title {
  font-weight: bold;
}

.settings__container__content__item__content {
  margin-top: 20px;
}

.changePasswordButton{
  padding: 10px;
  border-radius: 3px;
  background-color: var(--darkgreen);
  z-index: 10;
  width: 150px;
  margin-top: 25px;
  text-align: center;
  color: #fff;
}

.changePasswordButton button{
  color: #fff;
}

.setting_menu_button {
  position: relative;
  padding: 10px;
  border-radius: 3px;
  background-color: transparent;
  z-index: 10;
  width: 195px;
  text-align: left;
  color: var(--darkestgreen);
}

.setting_menu_button.active {
  position: relative;
  padding: 10px;
  border-radius: 3px;
  background-color: var(--lightgrey);
  color: var(--darkestgreen);
  z-index: 10;
  width: 195px;
  text-align: left;
}

.setting_menu_buttonWhite {
  position: relative;
  padding: 10px;
  border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  width: 195px;
  text-align: left;
}

.setting_to_map_button {
  background-color: var(--darkgreen);
  width: 150px;
  padding: 15px;
  color: #fff;
  border-radius: 3px;
  position: absolute;
  top: 50px;
  left: 1750px;
}

.settings_inner_container {
  display: flex;
  justify-content: space-between;
}

.square {
  background-color: #fff;
  border-radius: 5px;
  color: #073028;
  color: var(--darkestgreen);
  position: relative;
  max-height: 293px;
  width: 19.5%;
}

.square:hover {
  cursor: pointer;
  background-color: var(--hovergrey);
}

.square::before {
  content: "";
  float: left;
  padding-top: 100%;
}

.square::after {
  content: "";
  display: block;
  clear: both;
}

.settings_inner_container_Header {
  position: absolute;
  font-size: 18px;
  margin-top: 30px;
  margin-left: 30px;
}

.squareSkylark {
  border-radius: 5px;
  width: 19.5%;
  background-color: var(--darkgreen);
  position: relative;
  color: #fff;
}

.squareSkylark::before {
  content: "";
  float: left;
  padding-top: 100%;
}

.squareSkylark::after {
  content: "";
  display: block;
  clear: both;
}

.banner_updates {
  width: 100%;
  height: 250px;
  border-radius: 5px;
}

.banner_updates_header {
  font-size: 50px;
  position: absolute;
  color: #fff;
  font-weight: 600;
  margin-left: 60px;
  margin-top: 60px;
}

.banner_updates_subheader {
  font-size: 30px;
  position: absolute;
  color: #fff;
  font-weight: 400;
  margin-left: 60px;
  margin-top: 140px;
}

.banner_updates_button {
  background-color: #fff;
  color: var(--darkgreen);
  padding: 10px;
  width: 150px;
  margin-right: 120px;
  border-radius: 3px;
  margin-top: 130px;
  float: right;
}

.square_updates {
  border-radius: 5px;
  width: 24%;
  background-color: var(--lightgrey);
  position: relative;
}

.square_updates::before {
  content: "";
  float: left;
  padding-top: 100%;
}

.square_updates::after {
  content: "";
  display: block;
  clear: both;
}

.settings_inner_container_square_updates {
  position: absolute;
  font-size: 16px;
  margin-left: 0px;
}

.settings_inner_container_Headersquare_updates {
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 50px;
  color: var(--darkestgreen);
  font-weight: normal;
}

.settings__container_five_left {
  color: #073028;
  color: var(--darkestgreen);
  /* height: 900px; */
  line-height: 1.5;
  position: relative;
  max-height: 186px;
  /* right: 50px; */
  /* top: 50px; */
  width: calc(25% - 130px);
}

.settings__container_five_left_header {
  color: #073028;
  color: var(--darkestgreen);
  font-size: 22px;
  font-weight: 400;
  /* margin-bottom: 50px; */
  margin-top: 0px;
}

.settings__container_five_left_subheader {
  font-weight: 600;
}

.AccountSettings {
  width: 1600px;
  margin-left: 300px;
}

.AccountSettingsMobile {
  width: 100%;
  margin-left: 0px;
  left: 0px;
  position: absolute;
  z-index: 20;
  background-color: #fff;
  top: 120px;
  height: (100% - 120px);
}

.AccountSettingInnerMobile {
  border-radius: 5px;
  margin-top: 65px;
  margin-left: 50px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 300px;
  position: absolute;
  z-index: 20;
  background-color: var(--lightgrey);
}

.AccountSettingsinputGroupMobile {
  margin-bottom: 15px;
  margin-top: 15px;
  width: 500px;
}

.AccountSettingsinputGroupMobile label {
  display: block;
  width: 200px;
  padding: 8px;
}

.AccountSettingsinputGroupMobile input,
.AccountSettingsinputGroupMobile select {
  width: 100%;
  padding: 10px;
  border: 0px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 300px;
  font-size: 16px;
  margin-top: -30px;
}

.AccountSettingsPasswordButtonChangeMobile {
  margin-top: -20px;
  font-size: 12px;
}

.placeholderMobile {
  width: 100%;
  padding: 10px;
  border: 0px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 300px;
  background-color: #fff;
}

.AccountSettingsButtonSaveMobile {
  width: 150px;
  background-color: var(--darkgreen);
  color: #fff;
  border-radius: 3px;
  padding: 10px;
}

.AccountForm {
  margin-top: 60px;
}

.AccountSettingInner {
  border-radius: 5px;
  margin-top: 65px;
  margin-left: 50px;
  color: var(--slategrey);
  line-height: 1.5;
}

.AccountSettingInner h2 {
  margin-top: 98px;
  margin-bottom: 50px;
  color: var(--darkestgreen);
  font-weight: normal;
}

.AccountSettingsinputGroup {
  margin-bottom: 15px;
  margin-top: 15px;
  width: 500px;
}

.AccountSettingsinputGroup label {
  display: block;
  width: 200px;
  padding: 8px;
}

.placeholder {
  width: 100%;
  padding: 10px;
  border: 0px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 300px;
  float: right;
  background-color: #fff;
  margin-top: -30px;
}

.AccountSettingsPasswordButtonChange {
  position: absolute;
  margin-left: 460px;
  margin-top: -20px;
  font-size: 12px;
}

.AccountSettingsPasswordButtonDone {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  width: 150px;
  margin-left: 525px;
  margin-top: -30px;
}

.AccountSettingsinputGroup input,
.AccountSettingsinputGroup select {
  width: 100%;
  padding: 10px;
  border: 0px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 300px;
  font-size: 16px;
  float: right;
  margin-top: -30px;
}

.AccountSettingsbuttons {
  margin-top: 10px;
  top: 500px;
  position: absolute;
}

.buttons button {
  margin-right: 10px;
}

.AccountSettingsButtonSave {
  width: 150px;
  background-color: var(--darkgreen);
  color: #fff;
  border-radius: 3px;
  padding: 10px;
}

.AccountSettingsButtonCancel {
  width: 150px;
  background-color: #fff;
  color: #000;
  border-radius: 3px;
  padding: 10px;
}

.AccountSettingsdropdownselect {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: 0px;
  border-radius: 5px;
  appearance: none;
  background-color: #fff;
  cursor: pointer;
  width: 225px;
}

.feedbackForm{
  margin-top: 50px;
}

.feedbackFormName{
  display: block;
  border: 1px solid #e0e0e0;
  border-radius: 3px!important;
  box-sizing: border-box;
  margin-bottom: 25px;
  padding: 10px;
  width: 350px;
}

.feedbackFormEmail{
  display: block;
  border: 1px solid #e0e0e0;
  border-radius: 3px!important;
  box-sizing: border-box;
  margin-bottom: 25px;
  padding: 10px;
  width: 350px;
}

.feedbackFormMessage{
  display: block;
  border: 1px solid #e0e0e0;
  border-radius: 3px!important;
  box-sizing: border-box;
  margin-bottom: 25px;
  padding: 10px;
  max-height: 200px;
  max-width: 350px;
  min-height: 200px;
  min-width: 350px;
}

.feedbackFormSubmit{
  display: block;
  border-radius: 3px!important;
  box-sizing: border-box;
  margin-bottom: 25px;
  border: 0px;
  max-width: 150px;
  padding: 10px;
  margin-top: 25px;
  width: 350px;
  background-color: var(--darkgreen);
  color: #fff;
}

.feedbackFormSubmit:hover{
  cursor: pointer;
}

.about {
  /* font-family: Arial, sans-serif; */
  z-index: 20;
  position: absolute;
  overflow-y: hidden;
  top: 110px;
  right: 0px;
  border-radius: 3px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-left: 1px solid #CECECE;
}

.about_inner {
  max-width: 950px;
  width: 90%;
  position: absolute;
  left: 50%;
  top: 100px;
  padding: 0px 20px;
  transform: translate(-50%, 0%);
  background-color: transparent;
}

.about_inner_top_section {
  text-align: center;
}

.about_inner_top_section h2 {
  margin-top: 50px;
  font-size: 40px;
  color: var(--darkestgreen);
  font-weight: 400;
}

.about_inner_top_section p {
  margin-top: 50px;
  color: var(--slategrey);
  line-height: 1.5;
}

.about_button {
  padding: 10px;
  border-radius: 3px;
  background-color: var(--darkgreen);
  color: #fff;
  margin-top: 75px;
}

.tabsystem {
  margin-top: 75px;
  margin-bottom: 75px;
  border-bottom: 2px solid var(--hovergrey);
}

.abouttab_button {
  width: 100%;
  font-size: 22px;
  display: block;
  background-color: transparent;
  padding: 20px 0px;
  color: var(--darkestgreen);
  border-top: 2px solid var(--hovergrey);
  text-align: left;
}

.about_inner_lower_section {
  text-align: left;
  margin-top: 50px;
}

.about_inner_lower_section h2 {
  margin-top: 50px;
}

.about_inner_lower_section p {
  margin-top: 30px;
}

.tabContent {
  height: 0px;
  overflow: hidden;
  transition: height .5s ease-in-out;
  -webkit-transition: height .5s ease-in-out;
  -moz-transition: height .5s ease-in-out;
  -ms-transition: height .5s ease-in-out;
  -o-transition: height .5s ease-in-out;
}

.tabContentActive {
  overflow: hidden;
  height: auto;
  text-align: left;
  transition: height .5s ease-in-out;
  -webkit-transition: height .5s ease-in-out;
  -moz-transition: height .5s ease-in-out;
  -ms-transition: height .5s ease-in-out;
  -o-transition: height .5s ease-in-out;
}

.tabContentActive p {
  margin-top: 0px;
  margin-bottom: 30px;
}

.arrow_down {
  transition: transform .5s ease-in-out;
  width: 22px;
  justify-content: center;
  height: 22px;
  padding-top: 1px;
  text-align: center;
  float: right;
  -webkit-transition: transform .5s ease-in-out;
  -moz-transition: transform .5s ease-in-out;
  -ms-transition: transform .5s ease-in-out;
  -o-transition: transform .5s ease-in-out;
}

.arrow_rotate {
  transform: rotate(180deg);
}

.burgermenu {
  position: absolute;
  top: 7.5px;
  right: 20px;
  height: 33px;
  width: 33px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  border-radius: 30px;
}

.mobile-grid-item {
  text-align: center;
}

.mobile-grid-item_blocked {
  text-align: center;
  pointer-events: none;
  color: #bbb !important;
  opacity: .3;
}

.leftMenuIconsMobile {
  display: inline-block;
  margin-top: 5px;
  margin-left: 0%;
  margin-bottom: 12.5px;
}

.MobileIconButton {
  color: #000;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  width: 130px;
  font-size: 12px;
  top: 0px;
}

.MobileIconButton P {
  display: inline-block;
  top: -15px;
  margin-left: 10px;
  position: relative;
}

.box {
  box-sizing: border-box;
  background-color: transparent;
}

.items {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  transform: scale(0.98);
  will-change: transform;
  user-select: none;
  cursor: pointer;
  padding: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  display: grid;
  grid-auto-flow: column;
}

.items {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.items::-webkit-scrollbar {
  display: none;
}

.mobileDrawerInner {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100dvh - 214px);
}

.mobileDrawerInner h1 {
  font-weight: 400;
  font-weight: normal;
  color: var(--darkestgreen);
  font-size: 22px;
  margin-bottom: 20px;
}

.mobilepiechartdiv {
  margin-top: 0px;
  height: auto;
  position: relative;
}

.pieChartMobile {
  width: 50% !important;
  margin: auto;
}

.land_types_accum_mobile {
  position: relative;
  width: 100%;
  left: 30%;
  top: 25px;
  transform: translate(-27.5%, 0%);
  border-radius: 3px;
  background-color: #fff;
  z-index: 10;
  font-size: 12px;
  height: auto;
  overflow: hidden;
  overflow-x: hidden;
}


.land_types_accum_mobile::-webkit-scrollbar {
  width: 10px;
}


.land_types_accum_mobile::-webkit-scrollbar-thumb {
  background-color: var(--darkgreen);
  border-radius: 5px;
  border: 2px solid var(--lightgrey);
}

.land_types_accum_mobile::-webkit-scrollbar-track {
  background: var(--lightgrey);
  border-radius: 5px;
}

.marker {
  background-color: #ff0000;
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.AccountSettingsUser {
  margin-top: 50px;
  margin-bottom: 50px;
}

.AccountSettingsUserInner {
  border-radius: 5px;
  font-size: 24px;
  font-weight: 400;
}

.AccountSettingsUserDetails {
  margin-bottom: 15px;
  margin-top: 15px;
  width: 500px;
}

.feedbackFormInput {
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 400px;
  min-width: 400px;
  max-height: 200px;
  min-height: 200px;
  border-radius: 3px;
  border: 0px;
  resize: none;
  display: block;
  padding: 15px;
}

.feedbackFormInput::-webkit-input-placeholder {
  position: relative;
  font-size: 16px;
}

.Tabs {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 1200px;
}

.TabList {
  display: inline-block;
  border-radius: 3px;
  font-size: 24px;
}

.TabItem {
  border-radius: 3px;
  font-size: 22px;
  padding: 10px 20px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.TabItem:hover {
  cursor: pointer;
  background-color: var(--hovergrey);
}

.TabContent {
  padding: 10px;
  display: inline-block;
  width: 800px;
  height: auto;
  padding-bottom: 50px;
}

.TabContent h2 {
  font-size: 24px;
  padding: 0px;
  margin-top: 0px;
}

.linechart {
  padding: 10px;
  margin-top: 0px;
}

.linechartTitle{
  margin-top: 50px;
}

.APIMapLoad {
  position: absolute;
  z-index: 10;
  top: 50%;
  background-color: #fff;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 20px 20px 30px;
  border-radius: 3px;
  width: 960px;
  height: 550px;
  z-index: 20;
  -webkit-box-shadow: 0px -1px 16px -3px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px -1px 16px -3px rgba(0, 0, 0, 0.45);
  box-shadow: 0px -1px 16px -3px rgba(0, 0, 0, 0.45);
}

.APIMapLoadInnter {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 480px;
}

.loadingThumbnails {
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.loadingThumbnailsSpinner {
  display: block;
  margin-top: 10px;
}

.map-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 0;
  list-style-type: none;
}

.map-item {
  cursor: pointer;
  width: 300px;
  border-radius: 3px;
  height: 250px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: all 0.3s;
}

.map-item.highlighted {
  background-color: #ddd;
}

.map-item-name {
  position: relative;
  margin-top: -25px;
  left: 0px;
  float: left;
  height: 50px;
  width: 280px;
  /* background-color: #ff00ff; */
}

.LoadMenuscreenshot {
  background-color: var(--lightgrey);
  height: 195px;
  /* position: absolute; */
  border-radius: 3px;
  width: 275px;
  margin-top: -10px
}

.LoadMenuscreenshot img {
  height: 100%;
  width: 100%;
}

.map-list-info-cont {
  /* background-color: #07EA77; */
  height: 200px;
  width: 275px;
}

.map-name {
  text-align: left;
  /* background-color: #0000ff; */
  height: 25px;
  left: 0px;
  position: absolute;
  bottom: -10px;
}

.map-item:hover {
  background-color: #ddd;
  transition: all 0.3s;
}

/* NEW  ABOUT PAGE */

.aboutPageScroller {
  overflow-y: scroll !important;
  height: 100%;
  padding-bottom: 160px;
  overflow-x: hidden;
}

.newAboutHeader {
  background-color: transparent;
  min-height: calc(100dvh - 140px);
}

.newAboutHeaderBackground {
  z-index: -1;
}

.newAboutHeaderBackground img {
  width: 100%;
  z-index: -1;
  position: absolute;
  min-height: 100%;
}

.newAboutHeaderTitleIcon {
  position: relative;
  background-color: transparent;
  z-index: 10;
  text-align: center;
  height: 100px;
  width: 200px;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.newAboutHeaderTitle {
  position: relative;
  z-index: 10;
  margin-top: 250px;
  color: #fff;
  text-align: center;
  padding: 0px 20px;
}

.newAboutHeaderTitle h1 {
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -1px;
  margin: 30px 0px;
}

.newAboutHeaderTitleButton {
  position: relative;
  padding-top: 15px;
  z-index: 10;
  text-align: center;
  margin-bottom: 100px;
}

.newAboutHeaderTitleButton button {
  background-color: #fff;
  color: var(--slategrey);
  padding: 14px 20px;
  border-radius: 3px;
  width: 150px;
  font-size: 20px;
}

.newAboutHeaderSubTitle {
  position: relative;
  text-align: center;
  z-index: 10;
  font-size: 30px;
  margin: auto;
  width: 100%;
  color: #fff;
  max-width: 900px;
  padding: 0px 20px;
}

.newAboutHeaderSubTitle h2 {
  font-size: 50px;
  margin-top: 0px;
}

.newAboutHeaderHidden {
  padding-top: 0px;
  text-align: center;
  height: auto;
  color: #fff;
  padding: 100px 20px;
  background-color: #000;
  overflow: hidden;
}

.newAboutHeaderHiddenActive {
  text-align: center;
  padding: 100px 20px;
  height: auto;
  color: #fff;
  background-color: #000;
}

.newAboutHeaderHiddenSubTitleText {
  max-width: 800px;
  padding: 0px;
  margin: auto;
  position: relative;
  line-height: 1.5;
  color: var(--textgrey);
}

.newAboutHeaderInfo {
  background-color: #fff;
  padding-top: 100px;
}

.newAboutHeaderInfoTitle {
  position: relative;
  z-index: 10;
  text-align: center;
}

.newAboutHeaderInfoTitle h1 {
  font-weight: 800;
  color: var(--slategrey);
  padding: 0px 20px;
}

.newAboutHeaderInfoSubTitle {
  position: relative;
  z-index: 10;
  text-align: center;
  color: var(--slategrey);
  line-height: 1.5;
  padding: 0px 20px;
}

.newAboutHeaderInfocontainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 1300px;
  padding-top: 40px;
  padding-bottom: 80px;
}

.newAboutHeaderInfocontainer .box {
  padding: 0px 20px;
  flex: 1 0 33.333%;
  /* 1/3rd of the container's width */
  height: 175px;
  /* Set a fixed height or adjust as needed */
  /* Optional: for visual separation */
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.boxTitle {
  display: block;
  background-color: #fff;
  margin-bottom: 0px;
  position: relative;
}

.boxTitle h2 {
  font-weight: 400;
  color: var(--slategrey);
  margin-bottom: 15px;
}

.boxSubTitle {
  display: block;
  background-color: #fff;
  position: relative;
}

.boxSubTitle p {
  margin-top: 0px;
  font-size: 16px !important;
  line-height: 1.5;
  color: var(--midgrey);
}

.newAboutHeaderInfgraphicScroller::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.newAboutHeaderInfgraphicScroller {
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

/* For Internet Explorer and Edge */
.newAboutHeaderInfgraphicScroller {
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
}

.newAboutHeaderInfgraphic {
  background-color: var(--darkgreen);
  background-position: 100%;
  width: 100%;
}

.newAboutHeaderInfgraphicScroller {
  width: 100%;
  min-height: 75%;
  padding-top: 100px;
  padding-bottom: 100px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.newAboutHeaderInfgraphicPackContainer {
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;
  position: relative;
  width: 180vw;
  margin-left: 0%;
  align-items: center;
}

.newAboutHeaderInfgraphicPackleft {
  width: 80vw;
  background-color: transparent;
  float: left;
  left: 100px;
  position: relative;
  display: flex;
  align-items: center;
}

.newAboutHeaderInfgraphicPackright {
  width: 80vw;
  float: right;
  left: 100px;
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
}

.newAboutHeaderInfgraphicImage {
  background-color: transparent;
  height: 100%;
  width: 50%;
  margin-left: 5%;
  /* float: left; */
  /* left: 10%; */
  /* top: 20%; */
  /* position: relative; */
  display: inline-block;
}

.newAboutHeaderInfgraphicImage img {
  width: 100%;
  height: auto;
}

.newAboutHeaderInfgraphicTextRight {
  background-color: transparent;
  height: 100%;
  float: right;
  margin-right: 5%;
  width: 35%;
  /* right: 0%; */
  /* top: 0%; */
  display: inline-block;
  /* position: relative; */
  color: #fff;
  line-height: 1.5;
}

h1 {
  font-size: 40px;
  font-weight: 400;
}

.newAboutHeaderInfgraphicTextRight h1 {
  font-size: 60px;
  font-weight: 800;
  margin: 30px 0px;
  margin-top: 10px;
}

.newAboutHeaderInfgraphicTextRightLogo {}

.newAboutHeaderInfgraphicTextRightSubheader {}

.newAboutHeaderpartners {
  background-color: var(--lightgrey);
}

.newAboutHeaderpartnersTitle {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 0px 20px;
  padding-top: 100px;
}

.newAboutHeaderpartnersTitle h1 {
  margin: 0px;
  color: var(--slategrey);
  font-weight: 800;
}

.newAboutHeaderpartnersSubTitle {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 0px 20px;
  margin-top: 20px;
  color: var(--slategrey);
}

.newAboutHeaderpartnerscontainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 1300px;
  padding-top: 40px;
  padding-bottom: 80px;
}

.newAboutHeaderpartnerscontainer .box {
  flex: 1 0 33.333%;
  /* 1/3rd of the container's width */
  height: auto;
  /* Set a fixed height or adjust as needed */
  /* Optional: for visual separation */
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  padding: 20px;
}

.newAboutHeaderpartnerscontainer img {
  width: 75%;
  height: auto;
  max-width: 170px;
  margin-top: 20px;
}

.newAboutHeadercontact {
  background-color: var(--lightgrey);
  height: auto;
}


.newAboutHeadercontact h1 {
  margin: 0px;
  color: var(--slategrey);
  font-weight: 800;
}

.newAboutHeadercontactTitle {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 0px 20px;
  padding-top: 100px;
  color: var(--slategrey);
}

.newAboutHeadercontactSubTitle {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 0px 20px;
  margin-top: 20px;
  color: var(--slategrey);
}

.newAboutHeadercontactcontainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 1300px;
  padding: 0px 20px;
}

.newAboutHeadercontactSubcontainer {
  display: flex;
  max-width: 650px;
  margin: auto;
  margin-top: 80px;
  padding: 0px 20px;
  position: relative;
  flex-direction: column;
}

.newAboutHeadercontactSubcontainer label {
  margin: 10px 0 5px;
  font-weight: bold;
}

.newAboutHeadercontactSubcontainer input,
.newAboutHeadercontactSubcontainer select,
.newAboutHeadercontactSubcontainer textarea {
  padding: 10px;
  margin-bottom: 25px;
  border: 1px solid #E0E0E0;
  border-radius: 3px !important;
  width: 100%;
  box-sizing: border-box;
}

.newAboutHeadercontactSubcontainer select {
  height: 35px;
  background-color: #fff !important;
  height: auto;
}

.newAboutHeadercontactSubcontainer .splitInputLeft {
  width: 47.5% !important;
}

.newAboutHeadercontactSubcontainer .splitInputRight {
  width: 47.5% !important;
  float: right;
}

.newAboutHeadercontactSubcontainer button {
  padding: 10px;
  position: relative;
  background-color: var(--darkgreen);
  color: #fff;
  border: none;
  left: 50%;
  transform: translate(-50%);
  border-radius: 4px;
  cursor: pointer;
  width: 150px;
}

.newAboutHeadercontactSubcontainer button:hover {
  background-color: var(--darkgreen);
}

.newAboutHeaderfooter {
  background-color: var(--lightgrey);
}

.newAboutHeaderfooterTitle {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 100px 20px;
  padding-bottom: 50px;
}

.newAboutHeaderfooterTitle img {
  max-width: 180px;
}

.newAboutHeaderfooterSubTitle {
  position: relative;
  z-index: 10;
  text-align: center;
  color: var(--slategrey);
  padding-bottom: 200px;
}

.newAboutHeaderfootercontainer {
  display: flex;
  flex-wrap: wrap;
  top: 200px;
  color: #444E4F;
  position: relative;
  width: 70%;
  margin-left: 15%;
}

.loadingPerc {
  z-index: 100;
  position: absolute;
  background-color: #ff0000;
  height: 100px;
  width: 100px
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.loading_text {
  color: var(--darkgreen);
}

.loading_bar {
  width: 30%;
  /* Adjust as needed */
  height: 4px;
  /* Adjust as needed */
  background-color: #e0e0e0;
  /* Light grey background for the bar */
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 30px;
  /* Space between the bar and the percentage text */
}

.loading_bar_fill {
  height: 100%;
  background-color: var(--darkgreen);
  /* Color of the filling bar */
  transition: width 0.5s ease;
  /* Smooth transition */
}

.loading_text {
  font-size: 1.2em;
}

.chart_1_hover_information {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  border: 2px solid var(--lightgrey);
  border-radius: 3px;
  z-index: 10;
  display: block;
  margin-left: -10px;
  font-size: 10px;
  width: 150px;
  /* Add extra padding at the top to make space for the triangle */
  text-align: center;
}

.chart_1_hover_information::before {
  content: "";
  position: absolute;
  top: -20px;
  /* Adjust this value to position the triangle */
  left: 50%;
  /* Center the triangle */
  transform: translateX(-50%);
  /* Center the triangle horizontally */
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent var(--lightgrey);
  /* Triangle pointing down */
  /* Adjust triangle color to match background */
}

@media (max-width: 991px) {

  .controlsOpenButton,
  .controls_nav {
    bottom: 60px;
  }

  .newAboutHeaderTitle {
    margin-top: 150px;
  }

  .newAboutHeaderTitle h1,
  .newAboutHeaderSubTitle h2,
  .newAboutHeaderInfoTitle h1,
  .boxTitle h2,
  .newAboutHeaderpartnersTitle h1 {
    font-size: 30px;
  }

  .newAboutHeaderSubTitle {
    width: auto;
  }

  .newAboutHeaderInfocontainer {
    display: block;
  }

  .newAboutHeaderInfocontainer .box {
    margin-bottom: 40px;
    height: auto;
  }

  .newAboutHeaderInfgraphicPackleft,
  .newAboutHeaderInfgraphicPackright {
    width: 90vw;
    left: 10vw;
    display: block;
  }

  .newAboutHeaderInfgraphicImage {
    height: auto;
    width: 100%;
    margin-left: 0px;
  }

  .newAboutHeaderInfgraphicTextRight {
    height: auto;
    width: 90%;
    margin-right: 10%;
  }

  .newAboutHeadercontactcontainer {
    padding: 0px;
  }

  .newAboutHeaderBackground img {
    width: auto;
    height: 100%;
  }

  .aboutPageScroller {
    background-color: RGBA(0, 0, 0, 0.55);
  }

  .newAboutHeaderInfgraphicTextRightLogo svg {
    height: 40px;
    width: auto;
  }

  .newAboutHeaderInfgraphicTextRight h1 {
    font-size: 40px;
  }

  .land_types_accum_landkey {
    width: 100%;
    left: 0px;
    top: 30px;
    height: auto;
    transform: none;
    padding-bottom: 30px;
    overflow-y: auto;
  }

  .land_type_list_general_p,
  .land_type_list_general_p:hover {
    width: 100%;
  }

}

.modal-content__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 10px
}

.landtypeColorIcon{
  width: 40px;
  height: 40px;
  position: absolute;
  top: 15px;
  border-radius: 50%;
  right: 0px;
}